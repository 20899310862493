export const progressList = [
  {
    id: 1,
    courses: "Data management",
    progress: "5 of 12",
  },
  {
    id: 2,
    courses: "business analyst",
    progress: "5 of 12",
  },
  {
    id: 3,
    courses: "data science",
    progress: "5 of 12",
  },
  {
    id: 4,
    courses: "soft skills",
    progress: "5 of 12",
  },
];
