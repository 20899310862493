export const TOKEN = "BCT_LOGIN_STUDENT"

export const LOGOUT = "LOGOUT"
export const LOGOUT_LOADING = "LOGOUT_LOADING"
export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL"
export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL"
export const GET_USER = "GET_USER"
export const GET_USER_FAIL = "GET_USER_FAIL"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"
export const GET_USER_LOADING = "GET_USER_LOADING"
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING"

export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL"

// ERRORS
export const GET_ERRORS = "GET_ERRORS"
export const CLEAR_ERRORS = "CLEAR_ERRORS"
export const GET_ERRORS_TEXT = "GET_ERRORS_TEXT"

export const SET_SUCCESS = "SET_SUCCESS"
export const SET_SUCCESS_FAIL = "SET_SUCCESS_FAIL"

export const GET_USER_TYPE = "GET_USER_TYPE"
export const GET_USER_TYPE_FAIL = "GET_USER_TYPE_FAIL"

export const GET_PLANS = "GET_PLANS"

export const GET_COURSES_LOADING = "GET_COURSES_LOADING"
export const GET_COURSES = "GET_COURSES"
export const UPDATE_COURSES = "UPDATE_COURSES"
export const GET_COURSES_FAIL = "GET_COURSES_FAIL"
export const SEARCH_COURSES = "SEARCH_COURSES"
export const SEARCH_COURSES_LOADING = "SEARCH_COURSES_LOADING"
export const SEARCH_COURSES_FAIL = "SEARCH_COURSES_FAIL"

export const GET_COURSES_TO_ENROLL_LOADING = "GET_COURSES_TO_ENROLL_LOADING"
export const SEARCH_COURSES_TO_ENROLL = "SEARCH_COURSES_TO_ENROLL";
export const SEARCH_COURSES_TO_ENROLL_LOADING =
	"SEARCH_COURSES_TO_ENROLL_LOADING";
export const SEARCH_COURSES_TO_ENROLL_FAIL = "SEARCH_COURSES_TO_ENROLL_FAIL";
export const GET_COURSES_TO_ENROLL = "GET_COURSES_TO_ENROLL";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const SEND_NOTIFICATION = "SEND_NOTIFICATION"
export const GET_NOTIFICATION_LOADING = "GET_NOTIFICATION_LOADING"
export const GET_NOTIFICATION = "GET_NOTIFICATION"
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL"

export const UPDATE_DISCUSSION = "UPDATE_DISCUSSION";
export const GET_DISCUSSION = "GET_DISCUSSION";
export const GET_DISCUSSION_FAIL = "GET_DISCUSSION_FAIL";
export const SEND_DISCUSSION = "SEND_DISCUSSION";
export const SEND_DISCUSSION_FAIL = "SEND_DISCUSSION_FAIL";

export const GET_TASK = "GET_TASK";
export const GET_TASK_FAIL = "GET_TASK_FAIL";
export const UPDATE_TASK = "UPDATE_TASK";

export const CHAT = "CHAT";
export const CHAT_RELOAD = "CHAT_RELOAD";
export const CHAT_LOADING = "CHAT_LOADING";
export const CHAT_LOADING_MAIN = "CHAT_LOADING_MAIN";
export const CHAT_GET_MESSAGES = "CHAT_GET_MESSAGES";
export const CHAT_GET_MESSAGES_FAIL = "CHAT_GET_MESSAGES_FAIL";
export const GET_CHAT = "GET_CHAT";
export const GET_CHAT_LOADING = "GET_CHAT_LOADING";
export const GET_CHAT_FAIL = "GET_CHAT_FAIL";
export const CHAT_ADD_MESSAGE = "CHAT_ADD_MESSAGE";
export const CHAT_ADD_MESSAGE_FAIL = "CHAT_ADD_MESSAGE_FAIL";
export const CHAT_UPDATE_MESSAGE = "CHAT_UPDATE_MESSAGE";
export const CHAT_UPDATE_MESSAGE_FAIL = "CHAT_UPDATE_MESSAGE_FAIL";
export const CHAT_SOCKET_ADD_MESSAGE = "CHAT_SOCKET_ADD_MESSAGE";
export const SOCKET = "SOCKET";
export const SOCKET_ONLINE = "SOCKET_ONLINE";

export const ADD_SESSION = "ADD_SESSION";
export const ADD_SESSION_FAIL = "ADD_SESSION_FAIL";
export const UPDATE_SESSION = "UPDATE_SESSION";
export const GET_SESSION = "GET_SESSION";