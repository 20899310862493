import React from 'react'

const resume = () => {
  return (
    <div>
      rendered
    </div>
  )
}

export default resume